import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import {
  EMPTY,
  finalize,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs'
import { miscellaneousActions } from './miscellaneous.actions'

import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Store } from '@ngrx/store'
import { AccessorialChargeGroup } from '../domain/accesorial-charge-group.model'
import { BillingTerm } from '../domain/billing-term.model'
import { City } from '../domain/city.model'
import { Country } from '../domain/country.model'
import { Currency } from '../domain/currency.model'
import { Department } from '../domain/department.model'
import { HandlingUnitTypes } from '../domain/handling-unit-types.model'
import { State } from '../domain/state.model'
import { Timezone } from '../domain/timezone.model'
import { TransportationOption } from '../domain/transportation-options.model'
import { TransportationServiceType } from '../domain/transportation-service-type.model'
import { MapService } from '../infrastructure/map.service'
import { MiscellaneousService } from '../infrastructure/miscellaneous.service'
import { FromGetLocationResponse } from './adapter/FromGetLocationResponse'
import { MiscellaneousAdapter } from './adapter/MiscellaneousAdapter'
import {
  selectAccessorialChargeGroups,
  selectCountries,
  selectTransportationOptions
} from './miscellaneous.selectors'
import { KnownCountries } from './static/known-countries'
import { UserClass } from '../domain/user-class.model'

@Injectable()
export class StateEffects {
  private store = inject(Store)
  private actions$ = inject(Actions)
  private service = inject(MiscellaneousService)
  private configurationService = inject(MapService)
  private alertsFacade = inject(LegacyAlertsFacade)

  private miscellaneousAdapter = new MiscellaneousAdapter()

  constructor() {
    this.miscellaneousAdapter.set(FromGetLocationResponse)
  }

  loadTimezones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadTimezones),
      switchMap(() => this.service.getTimezones()),
      map(response =>
        response.map(
          timezone =>
            <Timezone>{
              displayName: timezone.displayName,
              id: timezone.timezoneId,
              standardName: timezone.standardName
            }
        )
      ),
      map(timezones => miscellaneousActions.loadTimezonesSuccess({ timezones }))
    )
  )

  loadCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadCurrencies),
      switchMap(() => this.service.getCurrencies()),
      map(response =>
        response.map(
          currency =>
            <Currency>{
              description: currency.description,
              id: currency.currencyId,
              code: currency.code
            }
        )
      ),
      map(currencies =>
        miscellaneousActions.loadCurrenciesSuccess({ currencies })
      )
    )
  )

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadContries),
      switchMap(() => this.service.getCountries()),
      map(response =>
        response.map(
          country =>
            <Country>{
              name: country.name,
              id: country.countryId,
              code: country.code
            }
        )
      ),
      map(countries => miscellaneousActions.loadContriesSuccess({ countries }))
    )
  )

  loadStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadStates),
      withLatestFrom(this.store.select(selectCountries)),
      switchMap(([payload, countries]) =>
        iif(
          () =>
            (countries.find(country => country.id == payload.countryId)?.states
              ?.length ?? 0) > 0,
          EMPTY.pipe(
            finalize(() =>
              this.store.dispatch({ type: `[CACHED]${payload.type}` })
            )
          ),
          of(payload)
        )
      ),

      //using mergeMap to avoid canceling the request when loading countries states in a for loop
      mergeMap(({ countryId }) =>
        this.service.getStates(countryId).pipe(
          map(response =>
            response
              .map(
                state =>
                  <State>{
                    name: state.name,
                    id: Number(state.stateId),
                    code: state.code,
                    countryId: Number(state.countryId)
                  }
              )
              .sort(({ code: a }, { code: b }) => a.localeCompare(b))
          ),
          map(states => miscellaneousActions.loadStatesSuccess({ states }))
        )
      )
    )
  )

  loadDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadDepartments),
      switchMap(() => this.service.getDepartments()),
      map(response => response as Department[]),
      map(departments =>
        miscellaneousActions.loadDepartmentsSuccess({ departments })
      )
    )
  )

  loadBillingTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadBillingTerms),
      switchMap(() => this.service.getBillingTerms()),
      map(response =>
        response.map(
          r =>
            <BillingTerm>{
              id: r.billingTermId,
              description: r.description
            }
        )
      ),
      map(terms => miscellaneousActions.loadBillingTermsSuccess({ terms }))
    )
  )

  loadTransportationServiceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadTransportationServiceTypes),
      switchMap(() => this.service.getTransportationServiceTypes()),
      map(response =>
        response.map(
          r =>
            <TransportationServiceType>{
              id: r.transportationServiceTypeId,
              description: r.description
            }
        )
      ),
      map(types =>
        miscellaneousActions.loadTransportationServiceTypesSuccess({
          types
        })
      )
    )
  )

  loadHandlingUnitTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadHandlingUnitTypes),
      switchMap(() => this.service.getHandlingUnitsTypes()),
      map(response =>
        response
          .map(
            r =>
              <HandlingUnitTypes>{
                typeId: r.handlingUnitId,
                description: r.description
              }
          )
          .sort((a, b) => a.description.localeCompare(b.description))
      ),
      map(handlingUnitTypes =>
        miscellaneousActions.loadHandlingUnitTypesSuccess({
          handlingUnitTypes
        })
      )
    )
  )

  loadAccessorialChargeGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadAccessorialChargeGroups),
      withLatestFrom(this.store.select(selectAccessorialChargeGroups)),
      switchMap(([payload, chargeGroups]) =>
        iif(
          () => chargeGroups?.length > 0,
          EMPTY.pipe(
            finalize(() =>
              this.store.dispatch({ type: `[CACHED]${payload.type}` })
            )
          ),
          of(payload)
        )
      ),
      switchMap(() =>
        this.service.getAccessorialChargeGroups().pipe(
          map(response =>
            response.map(
              (r): AccessorialChargeGroup => ({
                id: r.accessorialChargeTypeGroupId,
                description: r.description,
                chargeTypes: r.chargeTypes
                  .filter(ct => ct.chargeTypeId !== 79)
                  .map((ct): AccessorialChargeGroup['chargeTypes'][number] => ({
                    typeId: ct.chargeTypeId,
                    parentTypeId: ct.parentChargeTypeId,
                    description: ct.description,
                    tenantChargeTypes: ct.tenantChargeTypes.map(
                      tenantCharge => ({
                        id: tenantCharge.uuid,
                        code: tenantCharge.code,
                        description: tenantCharge.description,
                        isDefault: tenantCharge.isDefault ?? false
                      })
                    )
                  }))
                  .sort((a, b) => a.description.localeCompare(b.description))
              })
            )
          ),
          map(accesorialChargeGroups =>
            miscellaneousActions.loadAccessorialChargeGroupsSuccess({
              accesorialChargeGroups
            })
          )
        )
      )
    )
  )

  loadTransportationOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadTransportationOptions),
      concatLatestFrom(() => this.store.select(selectTransportationOptions)),

      switchMap(([payload, transportationOptions]) =>
        iif(
          () => transportationOptions.length > 0,
          EMPTY.pipe(
            finalize(() =>
              this.store.dispatch({ type: `[CACHED]${payload.type}` })
            )
          ),
          of(payload)
        )
      ),

      switchMap(() => this.service.getTransportationOptions()),
      map(response =>
        response
          .map<TransportationOption>(r => ({
            modeId: r.transportationModeId,
            description: r.description,
            serviceTypes: r.transportationServiceTypes
              .map(serviceType => ({
                id: serviceType.transportationServiceTypeId,
                description: serviceType.description,
                levels: serviceType.transportationServiceLevels?.map(level => ({
                  id: level.transportationServiceLevelId,
                  description: level.description
                }))
              }))
              .sort((compare, comparer) =>
                compare.description.localeCompare(comparer.description)
              )
          }))
          .sort((a, b) => a.description.localeCompare(b.description))
      ),
      map(transportationOptions =>
        miscellaneousActions.loadTransportationOptionsSuccess({
          transportationOptions
        })
      )
    )
  )

  loadLocationByZip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadLocationByZip),
      switchMap(({ zip, country }) =>
        this.configurationService
          .getLocation(zip, country.code as keyof typeof KnownCountries)
          .pipe(
            map(response => ({
              cities: this.miscellaneousAdapter.convert(
                FromGetLocationResponse,
                response
              ),
              zip,
              countryId: country.id
            })),
            map(cityAdapted =>
              cityAdapted.cities.map<City>(city => ({
                ...city,
                zip: cityAdapted.zip,
                countryId: cityAdapted.countryId
              }))
            )
          )
      ),
      tap(locations => {
        if (locations.length === 0) {
          this.alertsFacade.addAlert({
            alertType: 'information',
            label:
              'Not found locations for this postal code, please fill the fields.'
          })
        }
      }),
      map(locations =>
        miscellaneousActions.loadLocationByZipSuccess({
          locations
        })
      )
    )
  )

  loadUserClasses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miscellaneousActions.loadUserClasses),
      switchMap(() => this.service.getUserClasses()),
      map(response =>
        response.map(
          userClass =>
            <UserClass>{
              value: userClass.value,
              description: userClass.description
            }
        )
      ),
      map(userClasses =>
        miscellaneousActions.loadUserClassesSuccess({ userClasses })
      )
    )
  )
}
