import { Action, createFeature, createReducer, on } from '@ngrx/store'

import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { AccessorialChargeGroup } from '../domain/accesorial-charge-group.model'
import { BillingTerm } from '../domain/billing-term.model'
import { City } from '../domain/city.model'
import { Country } from '../domain/country.model'
import { Currency } from '../domain/currency.model'
import { Department } from '../domain/department.model'
import { HandlingUnitTypes } from '../domain/handling-unit-types.model'
import { Timezone } from '../domain/timezone.model'
import { TransportationOption } from '../domain/transportation-options.model'
import { TransportationServiceType } from '../domain/transportation-service-type.model'
import { miscellaneousActions } from './miscellaneous.actions'
import { UserClass } from '../domain/user-class.model'

export const MISCELLANEOUS_ENTITIES_FEATURE_KEY = 'static-entities'

export interface MiscellaneousState {
  timezones: Timezone[]
  currencies: Currency[]
  countries: EntityState<Country>
  departments: Department[]
  billingTerms: BillingTerm[]
  transportationServiceTypes: TransportationServiceType[]
  handlingUnitTypes: HandlingUnitTypes[]
  messureUnitTypes: ['IN', 'CM']
  weightUnitTypes: ['LBS', 'KG']
  accessorialChargeGroups: AccessorialChargeGroup[]
  transportationOptions: TransportationOption[]
  cities: EntityState<City>
  userClasses: UserClass[]
  layoutTitle: string
  layoutBreadcrumbs: string[]
}

export const countriesAdapter = createEntityAdapter<Country>()
export const citiesAdapter = createEntityAdapter<City>()

export interface MiscellaneousPartialState {
  readonly [MISCELLANEOUS_ENTITIES_FEATURE_KEY]: MiscellaneousState
}

export const initialMiscellaneousState: MiscellaneousState = {
  timezones: [],
  currencies: [],
  countries: countriesAdapter.getInitialState(),
  departments: [],
  billingTerms: [],
  transportationServiceTypes: [],
  handlingUnitTypes: [],
  messureUnitTypes: ['IN', 'CM'],
  weightUnitTypes: ['LBS', 'KG'],
  accessorialChargeGroups: [],
  transportationOptions: [],
  cities: citiesAdapter.getInitialState(),
  userClasses: [],
  layoutTitle: '',
  layoutBreadcrumbs: []
}

const reducer = createReducer(
  initialMiscellaneousState,
  on(miscellaneousActions.loadTimezonesSuccess, (state, { timezones }) => ({
    ...state,
    timezones
  })),
  on(miscellaneousActions.loadCurrenciesSuccess, (state, { currencies }) => ({
    ...state,
    currencies
  })),
  on(miscellaneousActions.loadContriesSuccess, (state, { countries }) => ({
    ...state,
    countries: countriesAdapter.upsertMany(countries, state.countries)
  })),
  on(miscellaneousActions.loadStatesSuccess, (state, { states }) => {
    if (states.length === 0) return state
    return {
      ...state,
      countries: countriesAdapter.upsertOne(
        <Country>{ id: states[0].countryId, states },
        state.countries
      )
    }
  }),
  on(miscellaneousActions.loadDepartmentsSuccess, (state, { departments }) => ({
    ...state,
    departments
  })),
  on(miscellaneousActions.loadBillingTermsSuccess, (state, { terms }) => ({
    ...state,
    billingTerms: terms
  })),
  on(
    miscellaneousActions.loadTransportationServiceTypesSuccess,
    (state, { types }) => ({
      ...state,
      transportationServiceTypes: types
    })
  ),
  on(
    miscellaneousActions.loadHandlingUnitTypesSuccess,
    (state, { handlingUnitTypes }) => ({
      ...state,
      handlingUnitTypes
    })
  ),
  on(
    miscellaneousActions.loadAccessorialChargeGroupsSuccess,
    (state, { accesorialChargeGroups }) => ({
      ...state,
      accessorialChargeGroups: accesorialChargeGroups
    })
  ),
  on(
    miscellaneousActions.loadTransportationOptionsSuccess,
    (state, { transportationOptions }) => ({
      ...state,
      transportationOptions
    })
  ),
  on(miscellaneousActions.loadLocationByZipSuccess, (state, { locations }) => ({
    ...state,
    cities: citiesAdapter.upsertMany(locations, state.cities)
  })),

  on(miscellaneousActions.setPageHeaderTitle, (state, { layoutTitle }) => ({
    ...state,
    layoutTitle
  })),
  on(
    miscellaneousActions.setPageBreadcrumbs,
    (state, { layoutBreadcrumbs }) => ({
      ...state,
      layoutBreadcrumbs
    })
  ),
  on(miscellaneousActions.loadUserClassesSuccess, (state, { userClasses }) => ({
    ...state,
    userClasses
  }))
)
export const miscellaneousFeature = createFeature({
  name: MISCELLANEOUS_ENTITIES_FEATURE_KEY,
  reducer: reducer
})
export function miscellaneousReducer(
  state: MiscellaneousState | undefined,
  action: Action
) {
  return reducer(state, action)
}
