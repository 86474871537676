import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  MISCELLANEOUS_ENTITIES_FEATURE_KEY,
  MiscellaneousState,
  citiesAdapter,
  countriesAdapter
} from './miscellaneous.reducer'

const { selectAll, selectEntities } = countriesAdapter.getSelectors()
const citySelectors = citiesAdapter.getSelectors()

export const miscellaneousState = createFeatureSelector<MiscellaneousState>(
  MISCELLANEOUS_ENTITIES_FEATURE_KEY
)

export const selectTimezones = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.timezones
)

export const selectCurrencies = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.currencies
)

export const selectDepartments = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.departments
)

export const selectCountries = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => selectAll(state.countries)
)

export const selectCountriesEntities = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => selectEntities(state.countries)
)

export const selectBillingTerms = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.billingTerms
)

export const selectTransportationServiceTypes = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.transportationServiceTypes
)

export const selectHandlingUnitTypes = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.handlingUnitTypes
)

export const selectMessureUnitTypes = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.messureUnitTypes
)
export const selectWeightUnitTypes = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.weightUnitTypes
)

export const selectAccessorialChargeGroups = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.accessorialChargeGroups
)

export const selectTransportationOptions = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.transportationOptions
)

export const selectAllCitiesByZip = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => citySelectors.selectAll(state.cities)
)

export const selectLayoutTitle = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.layoutTitle
)

export const selectLayoutBreadcrumbs = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.layoutBreadcrumbs
)

export const selectUserClasses = createSelector(
  miscellaneousState,
  (state: MiscellaneousState) => state.userClasses
)
