import { Injectable, inject } from '@angular/core'
import { Store } from '@ngrx/store'

import * as MiscellaneousFeature from './miscellaneous.reducer'
import {
  miscellaneousActions,
  setBreadcrumbs,
  setTitle
} from './miscellaneous.actions'
import { asap, asapScheduler, filter, firstValueFrom, map } from 'rxjs'
import {
  selectAccessorialChargeGroups,
  selectAllCitiesByZip,
  selectBillingTerms,
  selectCountries,
  selectCurrencies,
  selectDepartments,
  selectHandlingUnitTypes,
  selectLayoutBreadcrumbs,
  selectLayoutTitle,
  selectMessureUnitTypes,
  selectTimezones,
  selectTransportationOptions,
  selectTransportationServiceTypes,
  selectUserClasses,
  selectWeightUnitTypes
} from './miscellaneous.selectors'
import { Country } from '../domain/country.model'

@Injectable()
export class MiscellaneousFacade {
  private readonly store = inject(
    Store<MiscellaneousFeature.MiscellaneousPartialState>
  )

  allTimezones$ = this.store
    .select(selectTimezones)
    .pipe(filter(data => data.length > 0))

  allCurrencies$ = this.store
    .select(selectCurrencies)
    .pipe(filter(data => data.length > 0))

  allCountries$ = this.store
    .select(selectCountries)
    .pipe(filter(data => data.length > 0))

  allCountries = this.store.selectSignal(selectCountries)

  allCountriesAsObjectById$ = this.allCountries$.pipe(
    map(countries =>
      countries.reduce(
        (acc, country) => {
          acc[country.id] = country
          return acc
        },
        {} as { [key: string]: Country }
      )
    )
  )
  allCountriesAsObjectByCode$ = this.allCountries$.pipe(
    map(countries =>
      countries.reduce(
        (acc, country) => {
          acc[country.code] = country
          return acc
        },
        {} as { [key: string]: Country }
      )
    )
  )

  allStates$ = this.allCountries$.pipe(
    map(countries => countries.flatMap(country => country.states ?? []))
  )

  allDepartments$ = this.store
    .select(selectDepartments)
    .pipe(filter(data => data.length > 0))

  allBillingTerms$ = this.store
    .select(selectBillingTerms)
    .pipe(filter(data => data.length > 0))

  allTransportationServiceTypes$ = this.store
    .select(selectTransportationServiceTypes)
    .pipe(filter(data => data.length > 0))

  allHandlingUnitTypes$ = this.store
    .select(selectHandlingUnitTypes)
    .pipe(filter(data => data.length > 0))

  allHandlingUnitTypes = this.store.selectSignal(selectHandlingUnitTypes)

  allMessureUnitTypes$ = this.store
    .select(selectMessureUnitTypes)
    .pipe(filter(data => data.length > 0))

  allWeightUnitTypes$ = this.store
    .select(selectWeightUnitTypes)
    .pipe(filter(data => data.length > 0))

  allAccesorialChargeGroups$ = this.store
    .select(selectAccessorialChargeGroups)
    .pipe(filter(data => data.length > 0))

  allAccesorialChargeGroups = this.store.selectSignal(
    selectAccessorialChargeGroups
  )

  allTransportationOptions$ = this.store
    .select(selectTransportationOptions)
    .pipe(filter(data => data.length > 0))

  allCitiesByZip$ = this.store.select(selectAllCitiesByZip)

  allUserClasses$ = this.store
    .select(selectUserClasses)
    .pipe(filter(data => data.length > 0))

  selectedLayoutTitle$ = this.store.select(selectLayoutTitle)
  selectedLayoutBreadCrumbs$ = this.store.select(selectLayoutBreadcrumbs)

  loadTimezones() {
    this.store.dispatch(miscellaneousActions.loadTimezones())
  }

  loadCurrencies() {
    this.store.dispatch(miscellaneousActions.loadCurrencies())
  }

  loadDepartments() {
    this.store.dispatch(miscellaneousActions.loadDepartments())
  }

  loadCountries() {
    this.store.dispatch(miscellaneousActions.loadContries())
  }

  loadBillingTerms() {
    this.store.dispatch(miscellaneousActions.loadBillingTerms())
  }

  loadTransportationServiceTypes() {
    this.store.dispatch(miscellaneousActions.loadTransportationServiceTypes())
  }

  loadHandlingUnitTypes() {
    this.store.dispatch(miscellaneousActions.loadHandlingUnitTypes())
  }

  async loadStatesByContryCode(countryCode: string) {
    const countries = await firstValueFrom(this.allCountries$)
    const countryId = countries.find(
      country => country.code === countryCode
    )?.id
    if (!countryId) return
    this.store.dispatch(miscellaneousActions.loadStates({ countryId }))
  }

  loadStatesByCountryId(countryId: number) {
    if (countryId === undefined || countryId === null) return
    this.store.dispatch(miscellaneousActions.loadStates({ countryId }))
  }

  loadAccessorialChargeGroups() {
    this.store.dispatch(miscellaneousActions.loadAccessorialChargeGroups())
  }

  loadTransportationOptions() {
    this.store.dispatch(miscellaneousActions.loadTransportationOptions())
  }

  loadLocationsByZip(zip: string, country: Country) {
    this.store.dispatch(
      miscellaneousActions.loadLocationByZip({ zip, country })
    )
  }

  loadUserClasses() {
    this.store.dispatch(miscellaneousActions.loadUserClasses())
  }

  //#region to migrate to its own domain
  setTitle(title: string) {
    asapScheduler.schedule(() => {
      this.store.dispatch(setTitle({ title }))
      this.store.dispatch(
        miscellaneousActions.setPageHeaderTitle({ layoutTitle: title })
      )
    })
  }

  setBreadcrumb(breadcrumbs: string[]) {
    asapScheduler.schedule(() => {
      this.store.dispatch(setBreadcrumbs({ breadcrumbs }))
      this.store.dispatch(
        miscellaneousActions.setPageBreadcrumbs({
          layoutBreadcrumbs: breadcrumbs
        })
      )
    })
  }

  //#endregion
}
