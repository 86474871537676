import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { API_URL, HEADER_API_KEY } from '@navix/utils/tokens'
import { GetTimezonesResponse } from '../domain/get-timezones.response'
import { GetCurrenciesResponse } from '../domain/get-currencies.response'
import { GetCountriesResponse } from '../domain/get-countries.response'
import { GetBillingTermsResponse } from '../domain/get-billing-term.response'
import { GetTransportationServiceTypesResponse } from '../domain/get-transportation-service-types.response'
import { GetHandlingUnitTypesResponse } from '../domain/get-handling-unit-types.response'
import { GetAccessorialChargeGroupsResponse } from '../domain/get-accessorial-charge-groups.response'
import { GetTransportationOptionsResponse } from '../domain/get-transportation-options.response'
import { GetUserClassesResponse } from '../domain/get-user-classes.response'

@Injectable({
  providedIn: 'root'
})
export class MiscellaneousService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly apiMiscellaneousUrl = `${this.apiUrl}miscellaneous/` as const
  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  getTimezones(): Observable<GetTimezonesResponse> {
    return this._http.get<GetTimezonesResponse>(
      `${this.apiMiscellaneousUrl}timezones`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCurrencies(): Observable<GetCurrenciesResponse> {
    return this._http.get<GetCurrenciesResponse>(
      `${this.apiMiscellaneousUrl}currencies`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCountries(): Observable<GetCountriesResponse> {
    return this._http.get<GetCountriesResponse>(
      `${this.apiMiscellaneousUrl}countries`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getStates(countryId: number): Observable<GetCountriesResponse> {
    return this._http.get<GetCountriesResponse>(
      `${this.apiMiscellaneousUrl}countries/${countryId}/states`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getDepartments(): Observable<string[]> {
    return this._http.get<string[]>(`${this.apiMiscellaneousUrl}departments`, {
      headers: this.headersWithApiKey
    })
  }

  getBillingTerms(): Observable<GetBillingTermsResponse> {
    return this._http.get<GetBillingTermsResponse>(
      `${this.apiMiscellaneousUrl}billing-terms`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getTransportationServiceTypes(): Observable<GetTransportationServiceTypesResponse> {
    return this._http.get<GetTransportationServiceTypesResponse>(
      `${this.apiMiscellaneousUrl}transportation-service-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getHandlingUnitsTypes(): Observable<GetHandlingUnitTypesResponse> {
    return this._http.get<GetHandlingUnitTypesResponse>(
      `${this.apiMiscellaneousUrl}handling-units`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getAccessorialChargeGroups(): Observable<GetAccessorialChargeGroupsResponse> {
    return this._http.get<GetAccessorialChargeGroupsResponse>(
      `${this.apiMiscellaneousUrl}accessorial-charge-types/groups/new`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getTransportationOptions(): Observable<GetTransportationOptionsResponse> {
    return this._http.get<GetTransportationOptionsResponse>(
      `${this.apiMiscellaneousUrl}transportations/options`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getUserClasses(): Observable<GetUserClassesResponse> {
    return this._http.get<GetUserClassesResponse>(
      `${this.apiMiscellaneousUrl}user-classes`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
}
