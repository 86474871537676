import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { Timezone } from '../domain/timezone.model'
import { Currency } from '../domain/currency.model'
import { Country } from '../domain/country.model'
import { State } from '../domain/state.model'
import { Department } from '../domain/department.model'
import { BillingTerm } from '../domain/billing-term.model'
import { TransportationServiceType } from '../domain/transportation-service-type.model'
import { HandlingUnitTypes } from '../domain/handling-unit-types.model'
import { AccessorialChargeGroup } from '../domain/accesorial-charge-group.model'
import { TransportationOption } from '../domain/transportation-options.model'
import { City } from '../domain/city.model'
import { UserClass } from '../domain/user-class.model'

export const miscellaneousActions = createActionGroup({
  source: 'Static entities',
  events: {
    'load timezones': emptyProps(),
    'load timezones success': props<{ timezones: Timezone[] }>(),

    'load currencies': emptyProps(),
    'load currencies success': props<{ currencies: Currency[] }>(),

    'load contries': emptyProps(),
    'load contries success': props<{ countries: Country[] }>(),

    'load states': props<{ countryId: number }>(),
    'load states success': props<{ states: State[] }>(),

    'load departments': emptyProps(),
    'load departments success': props<{ departments: Department[] }>(),

    'load billing terms': emptyProps(),
    'load billing terms success': props<{ terms: BillingTerm[] }>(),

    'load transportation service types': emptyProps(),
    'load transportation service types success': props<{
      types: TransportationServiceType[]
    }>(),

    'load handling unit types': emptyProps(),
    'load handling unit types success': props<{
      handlingUnitTypes: HandlingUnitTypes[]
    }>(),

    'load accessorial charge groups': emptyProps(),
    'load accessorial charge groups success': props<{
      accesorialChargeGroups: AccessorialChargeGroup[]
    }>(),
    'load transportation options': emptyProps(),
    'load transportation options success': props<{
      transportationOptions: TransportationOption[]
    }>(),
    'load location by zip': props<{ zip: string; country: Country }>(),
    'load location by zip success': props<{ locations: City[] }>(),
    'load location by zip fail': props<{ error: unknown }>(),

    'set page header title': props<{ layoutTitle: string }>(),
    'set page breadcrumbs': props<{ layoutBreadcrumbs: string[] }>(),

    'load user classes': emptyProps(),
    'load user classes success': props<{ userClasses: UserClass[] }>()
  }
})

//Todo: Temporal mirror of app Actions.
const NAME = '[APP]'

export const ActionType = {
  SET_TITLE: `${NAME} SET_TITLE`,
  SET_BREADCRUMBS: `${NAME} SET_BREADCRUMBS`
}

export const setTitle = createAction(
  ActionType.SET_TITLE,
  props<{ title: string }>()
)

export const setBreadcrumbs = createAction(
  ActionType.SET_BREADCRUMBS,
  props<{ breadcrumbs: string[] }>()
)
