import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import GetLocationResponse from '../domain/get-location.response'

enum GoogleMapsCountryCodes {
  USA = 'US',
  CAN = 'CA',
  MEX = 'MX'
}

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl =
    `https://maps.googleapis.com/maps/api/geocode/json` as const
  private readonly key = `AIzaSyD-gC-aAyIN6PPnH9poL8v4eqJSf2WSPjM` as const

  getLocation = (
    zip: string,
    countryCode: keyof typeof GoogleMapsCountryCodes
  ): Observable<GetLocationResponse> => {
    const mapCountryCode = GoogleMapsCountryCodes[countryCode]

    return this._http.get<GetLocationResponse>(
      `${this.apiUrl}?address=${zip}&components=country:${mapCountryCode}&sensor=true&key=${this.key}`
    )
  }
}
