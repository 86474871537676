import { provideState } from '@ngrx/store'
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core'
import { provideEffects } from '@ngrx/effects'
import { StateEffects } from '../+state/miscellaneous.effects'
import { miscellaneousFeature } from '../+state/miscellaneous.reducer'
import { MiscellaneousFacade } from '../+state/miscellaneous.facade'

export function provideMiscellaneousFeature(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideEffects(StateEffects),
    provideState(miscellaneousFeature),
    MiscellaneousFacade
  ])
}
