import { IStrategy } from '@navix/utils/adapter'
import { City } from '../../domain/city.model'
import GetLocationResponse from '../../domain/get-location.response'

export class FromGetLocationResponse
  implements IStrategy<GetLocationResponse, City[]>
{
  convert(response: GetLocationResponse): City[] {
    if (!Array.isArray(response.results)) return []

    return response.results?.flatMap(city => {
      const validState = city.address_components.find(address =>
        address.types.includes('administrative_area_level_1')
      )

      if (validState === undefined) return []

      if (!city.postcode_localities) {
        return [
          {
            id: city.place_id + city.address_components[1].short_name,
            description: city.address_components[1].short_name,
            stateCode: validState.short_name
          } as City
        ]
      }

      return city.postcode_localities.map<City>(
        locality =>
          ({
            id: city.place_id + locality,
            description: locality,
            stateCode: validState.short_name
          }) as City
      )
    })
  }
}
